import React, { useState } from 'react';
import MUICard from '../MUICard';
import Slider from "react-slick";
import '../../App.css';
import MUICard3 from '../MUICard3';
import Highlights from '../Highlights';
import MUICard4 from '../MUICard4';
import MUIHeroSection from '../MUIHeroSection';
import { Box, Typography } from '@mui/material';

function Home() {
  const [slidesData] = useState([
    {
      image: "images/Barrie-Concerts/2024-2025/bc_26_04_25.jpg",
      title: "Barrie Concert Association",
      description: "Bringing world-class concert music performances to Barrie",
      buttonText: "2024/2025 Season",
      heroSectionLink: "/Events"
    },
    {
      image: "images/Georgian-Music/2024-2025/gm_13-04-25.jpg",
      title: "Georgian Music ",
      description: "Sunday afternoons | Featuring Piano Duo and others",
      buttonText: "Explore More",
      heroSectionLink: "/GeorgianMusic"
    },
    {
      image: "images/Barrie-Concerts/2024-2025/bc_29_03_25.jpg",
      title: "Barrie Concerts",
      description: "Saturday evenings | Featuring Francine Kay and others",
      buttonText: "Explore More",
      heroSectionLink: "/BarrieConcerts"
    }
  ]);



  const georgianMusicData = [
    {
        src: "images/Georgian-Music/2024-2025/gm_15-09-24.jpg",
        text_date: "Sunday 15 September 2024 3.00pm",
        text: "Andromeda Trio",
        text_description: "Tchaikovsky, Shostakovich, Haydn",
        text_location: "Bethel Community Church",
        label: 'Georgian Music',
        path: '/SingleEvent'
    },
    {
        src: "images/Georgian-Music/2024-2025/gm_03-11-24.jpg",
        text_date: "Sunday 03 November 2024 3.00pm",
        text: "Michael Kim",
        text_description: "Pianist Michael Kim",
        text_location: "Bethel Community Church",
        label: 'Georgian Music',
        path: '/SingleEvent2'
    },
    {
        src: "images/Georgian-Music/2024-2025/gm_15-12-24.jpg",
        text_date: "Sunday 15 December 2024 3.00pm",
        text: "Venuti Quartet",
        text_description: "Indigo",
        text_location: "Bethel Community Church",
        label: 'Georgian Music',
        path: '/SingleEvent3'
    },
    {
        src: "images/Georgian-Music/2024-2025/gm_02-02-25.jpg",
        text_date: "Sunday 02 February 2025 3.00pm",
        text: "Hall & Farley",
        text_description: "A Nightingale Sang",
        text_location: "Bethel Community Church",
        label: 'Georgian Music',
        path: '/SingleEvent4'
    },
    {
        src: "images/Georgian-Music/2024-2025/gm_13-04-25.jpg",
        text_date: "Sunday 13 April 2025 3.00pm",
        text: "Piano Duo",
        text_description: "The World of Yesterday",
        text_location: "Bethel Community Church",
        label: 'Georgian Music',
        path: '/SingleEvent5'
    }
  ];

  const barrieConcertData = [
    {
      src: "images/Barrie-Concerts/2024-2025/bc_05-10-24.jpg",
      text_date: "Saturday 19 October 2024 7.30pm ",
      text: "Duo Turgeon",
      text_description: "For Bruce with Love! ",
      text_location: "Hiway Church",
      label: "Barrie Concerts",
      path: "/SingleEvent6"
    },
    {
      src: "images/Barrie-Concerts/2024-2025/bc_30-11-24.jpg",
      text_date: "Saturday 30 November 2024 7.30pm",
      text: "Dévah Quartet",
      text_description: "Dévah Quartet",
      text_location: "Hiway Church",
      label: "Barrie Concerts",
      path: "/SingleEvent7"
    },
    {
      src: "images/Barrie-Concerts/2024-2025/bc_18_01_25.jpg",
      text_date: "Saturday 18 January 2025 7.30pm",
      text: "True North Brass",
      text_description: "Dancing Across the Globe",
      text_location: "Hiway Church",
      label: "Barrie Concerts",
      path: "/SingleEvent8"
    },
    {
      src: "images/Barrie-Concerts/2024-2025/bc_22_02_25.jpg",
      text_date: "Saturday 22 February 2025 7.30pm",
      text: "Ensemble Caprice",
      text_description: "Vivaldi on Fire",
      text_location: "Hiway Church",
      label: "Barrie Concerts",
      path: "/SingleEvent9"
    },
    {
      src: "images/Barrie-Concerts/2024-2025/bc_29_03_25.jpg",
      text_date: "Saturday 29 March 2025 7.30pm",
      text: "Francine Kay",
      text_description: "Things Lived and Dreamt",
      text_location: "Hiway Church",
      label: "Barrie Concerts",
      path: "/SingleEvent10"
    },
    {
      src: "images/Barrie-Concerts/2024-2025/bc_26_04_25.jpg",
      text_date: "Saturday 26 April 2025 7.30pm",
      text: "Piano virtuosity at its finest",
      text_description: "Toronto Concert Orchestra",
      text_location: "Hiway Church",
      label: "Barrie Concerts",
      path: "/SingleEvent11"
    }
  ];

  const highlightData = [
    {
      title: "Spirit '20' | September 30, 2023",
      description: '  Reviving the Roaring Twenties with Masterful Musicianship and Jazz Improvisation',
      imageUrl: 'images/News/bc_30-09-23_lgabout.jpg',
      link: '/News1',
    },
    {
      title: 'Ladom Ensemble | April 2, 2024',
      description: 'Experience the Unique Sound of Ladom Ensemble at Bethel Community Church',
      imageUrl: 'images/News/gm_04-02-24_lgabout.jpg',
      link: '/News2',
    },
    {
      title: 'Exciting News! Our New Website is Live!',
      description: 'Discover a new way to experience world-class music with our fresh online platform',
      imageUrl: 'images/News/Announcement.jpg',
      link: '/News3',
    },
  ];


  const card4Data = [
    {
      src: "images/Logo/TD.jpg",
      url:"https://www.kamg.ca" 
    },
    {
      src: "images/Logo/Brian.jpg",
    }
  ];

  return (
    <Box sx={{ backgroundColor: '#3D3D3D' }}>
      <MUIHeroSection slides={slidesData} />
      <Box className='cards'>
        <Box className='cards__container'>
          <Box className='cards__wrapper' sx={{ paddingX: 3, marginTop:6 }}>
            {/* Georgian Music */}
            <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#fff',textAlign: 'center'}}>
              Georgian Music
            </Typography>
            <MUICard data={georgianMusicData} />
            {/* Barrie Concert */}
            <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#fff', textAlign: 'center'}}>
              Barrie Concerts
            </Typography>
            <MUICard data={barrieConcertData} />
          </Box>
        </Box>
      </Box>
      <Box>
      <MUICard3 />
      </Box>
      <Box>
      <Highlights highlights={highlightData} />
      </Box>
      <Box>
      <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#fff',textAlign: 'center'}}>
      Gold Sponsors & Donors
      </Typography>  
      <MUICard4 cards4Data={card4Data}/>
      </Box>
    </Box>
  );
}

export default Home;
