// SingleEvent.js
import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent4() {

    const [heroSectionEventData] = useState({
        image: "images/Georgian-Music/2024-2025/gm_02-02-25.jpg",
        title: 'A Nightingale Sang',
        subtitle: 'Hall & Farley',
        date: 'Sunday 02 February 2025 3.00pm',
        location: 'Bethel Community Church, 128 St. Vincent St., Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/georgian-music-sun-series-2024-2025-bca/'
    });

    const [eventData] = useState({
        program: `Bernard Farley (Guitar): 
Praised for his "superb technique coupled with rare
expressivity" (Kitchener-Waterloo Record) Bernard
Farley has performed as soloist and chamber musician
in concerts and festivals in Canada, the Caribbean, the
United States and Europe. With his wife, soprano
Meredith Hall, he has toured throughout Canada and
Japan and has recorded a CD of folksongs, arias, and
lieder entitled My Fond Heart. Bernard has been
broadcast on Radio France, NPR and CBC. A native of
Toronto, his first teacher was Eli Kassner, with whom
he studied at the University of Toronto. He went on to
study historical guitar repertoire and technique with
Patrick O'Brien in New York.`,

        performers: `
Canadian soprano Meredith Hall delights audiences with her “lustrous sound and fluent legato”
(San Francisco Chronicle) and “bravura musical performance matched by a riveting sense of the
dramatic” (Boston Globe).
Ms. Hall has performed the title roles of numerous operas including: Monteverdi's
L'INCORONAZIONE DI POPPEA (Houston Grand Opera); Handel's PARTENOPE (Göttingen
Handel Festival, Germany); Rameau's ZÉPHYRE (Philharmonia Baroque, San Francisco);
Purcell's DIDO AND AENEAS (Apollo's Fire, Cleveland); Rauzzini's PIRAMO E TISBE (Capella
Savaria, Hungary), and Handel's SEMELE (Handel and Haydn Society, Boston).
Concert highlights include Benjamin Britten's LES ILLUMINATIONS (Talisker Players, Toronto),
Aaron Copland's POEMS OF EMILY DICKINSON (Memphis Symphony), and Resphighi’s IL
TRAMONTO with the St. Lawrence String Quartet.
Ms. Hall has sung the oratorio masterworks of Bach, Handel, Schubert and Mozart with
renowned organizations such as the Orchestre Symphonique de Montréal, Les Violons du Roi,
Tafelmusik, and the Toronto Symphony.
Toronto audiences have enjoyed Ms. Hall's performances in Rameau's HIPPOLYTE ET ARICIE
and Handel's ORLANDO for VOICEBOX: Opera in Concert, as well as Pamina in THE MAGIC
FLUTE, and Zerlina in DON GIOVANNI for Opera Atelier, with whom she also performed
Susanna in THE MARRIAGE OF FIGARO, in Tokyo. She has sung leading roles in historic
venues such as the Royal Albert Hall, the Palace of Versailles, and the Opera Comique de
Paris.
A proud Newfoundlander, Ms. Hall's first love is folk music. With Ensemble La Nef of Montréal
she has created many projects which build on her Celtic roots, including 3 CD'S and a folk
masque entitled “The Maid of Newfoundland". SACRUM MYSTERIUM, her Christmas concert
and with Apollo's Fire (Cleveland) has received rave reviews and toured to sold out audiences
across the United States.
She also records and tours internationally with her husband, classical guitarist Bernard Farley.`,
        performerLink: 'https://www.deanartists.com/meredith-hall',
        ticketInfo: `Purchase your season subscription early. 5 amazing upcoming concerts. 
        Adults Live-streaming: Available at the time of your choosing for up to 30 days after the concert - $90. 
        Adults In-person: - $130. 
        Students: $25 either in-person or live-streamed. 
        Single Tickets: These will be made available for each concert starting about 30 days before the event.`,
    });
    

    const georgianMusic = [
        {
            title: 'Venuti Quartet',
            description: 'Indigo',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_15-12-24.jpg',
            link: '/SingleEvent3'
        },
        {
            title: 'Hall & Farley',
            description: 'A Nightingale Sang',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_02-02-25.jpg',
            link: '/SingleEvent4'
        },
        {
            title: 'Piano Duo',
            description: 'The World of Yesterday',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_13-04-25.jpg',
            link: '/SingleEvent5'
        }
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink} 
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
                performance={eventData.performance}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Georgian Music" events={georgianMusic} buttonLink="/GeorgianMusic" />
            </Box>
        </Box>
    );
}

export default SingleEvent4;
