import React, { useState } from "react";
import '../../App.css';
import ArticleCard from "../ArticleCard";
import MUICard2 from "../MUICard2";
import { Box } from "@mui/material";
import MUISingleEventCard from "../MUISingleEventCard";

function SingleEvent3() {

    const [heroSectionEventData] = useState({
        image: "images/Georgian-Music/2024-2025/gm_15-12-24.jpg",
        title: 'Indigo',
        subtitle: 'Venuti Quartet',
        date: 'Sunday 15 December 2024 3.00pm',
        location: 'Bethel Community Church, 128 St. Vincent St., Barrie',
        buttonText: 'Book Tickets',
        buttonLink: 'https://www.showpass.com/venuti-quartet/'
    });

    const [eventData] = useState({
        program: `Erwin Schulhoff: 
    5 Pieces for String Quartet
        - Viennese Waltz
        - Serenade
        - Czech folk music
        - Tango
        - Tarantella

Drew Jurecka: 
    String Quartet
        - Allegro
        - Scherzo
        - Indigo
        - Fast Swing

Intermission

Fredrik Sjölin (arr.):
    Polska from Dorotea

Maurice Ravel String Quartet in F, M. 35 (1903)
`,
        performers: ` Rebekah Wolkstein (violin); Drew Jurecka(violin); Shannon Knights(viola); Amahl Arulanandam(cello)`,
        performerLink: 'https://venutistringquartet.com/',
        ticketInfo: `Purchase your season subscription early. 5 amazing upcoming concerts. 
        Adults Live-streaming: Available at the time of your choosing for up to 30 days after the concert - $90. 
        Adults In-person: - $130. 
        Students: $25 either in-person or live-streamed. 
        Single Tickets: These will be made available for each concert starting about 30 days before the event.`,
    });
    

    const georgianMusic = [

        {
            title: 'Pianist Michael Kim',
            description: 'Michael Kim',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_03-11-24.jpg',
            link: '/SingleEvent2'
        },
        {
            title: 'Hall & Farley',
            description: 'A Nightingale Sang',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_02-02-25.jpg',
            link: '/SingleEvent4'
        },
        {
            title: 'Piano Duo',
            description: 'The World of Yesterday',
            imageUrl: 'images/Georgian-Music/2024-2025/gm_13-04-25.jpg',
            link: '/SingleEvent5'
        }
    ];
    

    return (
        <Box> 
            <MUISingleEventCard
                image={heroSectionEventData.image}
                title={heroSectionEventData.title}
                subtitle={heroSectionEventData.subtitle}
                date={heroSectionEventData.date}
                location={heroSectionEventData.location}
                buttonText={heroSectionEventData.buttonText}
                buttonLink={heroSectionEventData.buttonLink} 
            />
            <ArticleCard 
                title={eventData.title}
                subtitle={eventData.subtitle}
                date={eventData.date}
                location={eventData.location}
                program={eventData.program}
                performers={eventData.performers}
                performerLink={eventData.performerLink}
                ticketInfo={eventData.ticketInfo}
                performance={eventData.performance}
            />
            <Box marginY='30px'>
                <MUICard2 title="2024/25 Georgian Music" events={georgianMusic} buttonLink="/GeorgianMusic" />
            </Box>
        </Box>
    );
}

export default SingleEvent3;